/* eslint-disable no-nested-ternary */
<template>
  <form
    style="display: contents"
    @submit.prevent="save"
  >
    <div
      v-if="model"
      class="section__reference"
    >
      <!-- Client name -->
      <div class="form-group">
        <label :for="`assignNew_relation`">
          {{ $t('account.account.myCustomers.relation') }}
        </label>

        <relation-input
          :value="relationOfReference(model)"
          @input="setRelationOfReference($event)"
        />
      </div>

      <!-- Notify email -->
      <div
        v-if="canSendEmail(model)"
        class="form-group form-group--checkbox"
      >
        <checkbox
          :value="model.opts.sendEmail"
          @input="model = { ...model, opts: { ...model.opts, sendEmail: $event}}"
        >
          {{ $t("account.account.myCustomers.assignSendEmail") }}
        </checkbox>
      </div>

      <!-- Stock status -->
      <div
        v-if="model.serial"
        class="form-group"
      >
        <label for="stockStatus">
          {{ $t('account.account.myCustomers.setStockStatus') }}
        </label>

        <status-select
          id="stockStatus"
          :value="model.opts.stockStatus"
          :statuses="relationOfReference(model)?stockStatusses.assigned :stockStatusses.unassigned"
          @input="model = { ...model, opts: { ...model.opts, stockStatus: $event}}"
        />
      </div>
      <!-- Stock location -->
      <div
        v-if="model.serial && ['available','reserved'].includes(model.opts.stockStatus)"
        class="form-group"
      >
        <location-select
          v-if="locations.length>=1"
          :value="model.opts.stockAddress"
          class="location-select"
          @input="model = { ...model, opts: { ...model.opts, stockAddress: $event}}"
        />
      </div>

      <!-- Purchase date -->
      <div
        v-if="model.serial && model.opts.stockStatus === 'sold' && model.email"
        class="form-group"
      >
        <label>
          {{ $t('account.account.myCustomers.purchaseDate') }}
        </label>

        <datepicker
          :value="model.opts.purchaseDate"
          maximum-view="month"
          :language="lang"
          :format="dateFormatter"
          @input="model = { ...model, opts: { ...model.opts, purchaseDate: $event}}"
        />
      </div>

      <!-- Note -->
      <div class="form-group">
        <label :for="`${model.key}_note`">
          {{ $t('account.account.myOrders.notes') }}
        </label>
        <textarea
          :id="`${model.key}_note`"
          :value="model.note"
          rows="2"
          class="form-control"
          maxlength="255"
          @input="model = {...model, note: $event.target.value}"
        />
      </div>
    </div>
    <Message
      v-if="errorMessage"
      type="error"
    >
      {{ errorMessage }}
    </Message>
  </form>
</template>
<script>

import Message from '@/components/Message.vue';
import dialogs from '@/utils/dialogs';
import { formatDate } from '@/utils/dateUtils';
import { isEqual } from '@/utils/object';
import { mapState } from 'vuex';
import { relationOfReference } from '@/views/account/account/mycustomers/utils';
import statusSelect from '@/views/account/account/mystock/statusSelect.vue';
import checkbox from '@/elements/checkbox.vue';
import translations from '@/translations';
import Datepicker from 'vuejs-datepicker';
import RelationInput from '../mycustomers/relationInput.vue';
import LocationSelect from '../mystock/locationSelect.vue';

// Elements
export default {
  components: {
    Message,
    RelationInput,
    checkbox,
    statusSelect,
    Datepicker,
    LocationSelect,
  },
  model: {
    prop: 'reference',
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderline: {
      type: Object,
      required: true,
    },
    reference: {
      type: Object,
      default: () => {},
    },
    itemIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      today,
      initialReference: { ...this.reference },
      model: {
        ...this.reference,
        key: `${this.orderline?.orderline || 'unknown'}_${this.itemIndex + 1}`,
        index: this.itemIndex + 1,
        quantity: 1,
        orderline: this.orderline?.orderline,
        opts: {
          sendEmail: this.reference?.opts?.sendEmail || false,
          purchaseDate: this.reference?.opts?.purchaseDate || this.stockItem?.registration?.purchaseDate || this.stockItem?.stock?.orderdate || this.today,
          stockStatus: this.reference?.opts?.stockStatus || this.stockItem?.stock?.status || (relationOfReference(this.reference) ? 'reserved' : null),
          stockAddress: this.reference?.opts?.stockAddress || this.stockItem?.stock?.address?.externalid,
        },
      },
      isLoading: false,
      errorMessage: null,
      stockStatusses: {
        assigned: ['sold', 'reserved'],
        unassigned: ['available', 'sold', 'hidden', 'reserved'],
      },
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language.current,
      user: (state) => state.auth.user,
      customer: (state) => state.customer,
      locations: (state) => state.customer.selectedCustomer.addresses,
    }),
    customerId() {
      return this.customer.selectedCustomerId;
    },
    orderid() {
      return this.order.externalorderid || this.order.id;
    },
    lang() {
      // Need this for the datepicker!
      return translations[this.$store.state.language.current].Datepicker;
    },
    locale() {
      return this.$store.state.language.current;
    },
    stockItem() {
      const itemsBySerial = new Map((this.orderline.items || []).map((i) => [i.serial, i]));
      return itemsBySerial.get(this.reference.serial);
    },
  },
  watch: {
    model() {
      this.$emit('input', this.model);
    },
  },
  mounted() {
  },
  methods: {
    async beforeClose(result, source) {
      if (source === 'dismiss') {
        // const a = JSON.parse(JSON.stringify(this.model));
        // const b = JSON.parse(JSON.stringify(this.valueToModel(false)));
        const a = this.model;
        const b = this.valueToModel(false);

        if (!isEqual(a, b)) {
          const confirmed = await dialogs.show({
            title: this.$t('general.unsavedChanges.title'),
            content: this.$t('general.unsavedChanges.content'),
            type: 'cancelYes',
          });
          if (confirmed) {
            return true;
          }
          return false;
        }
      }
      return undefined;
    },
    close(result, source = 'cancel') {
      return this.overlay.close(result, source);
    },
    deleteReference(ref) {
      this.model = this.model.filter((x) => x !== ref);
    },
    relationOfReference,
    setRelationOfReference(newRelation) {
      const newReference = { ...this.model };
      const oldRelation = relationOfReference(this.model);
      if (newRelation) {
        newReference.relationId = newRelation.id;
        newReference.firstName = newRelation.firstName;
        newReference.lastName = newRelation.lastName;
        newReference.email = newRelation.email;
        newReference.phone = newRelation.phone;
        newReference.language = newRelation.language;
        if (!['reserved', 'sold'].includes(newReference.opts.stockStatus)) {
          newReference.opts.stockStatus = 'reserved';
        }
        newReference.opts.sendEmail = this.canSendEmail(newReference);
      } else if (!newRelation) {
        newReference.relationId = null;
        newReference.firstName = null;
        newReference.lastName = null;
        newReference.email = null;
        newReference.phone = null;
        newReference.opts.sendEmail = false;

        if (oldRelation && ['reserved', 'sold'].includes(newReference.opts.stockStatus)) {
          newReference.opts.stockStatus = 'available';
        }
      }
      this.model = newReference;
    },
    dateFormatter(date) { return formatDate(date, this.locale); },
    canSendEmail(reference) {
      if (!reference.email) return false;
      const oldReference = Object.keys(this.initialReference).length > 0 ? this.initialReference : undefined;
      if (!oldReference) return true;
      if (JSON.stringify({
        firstName: reference.firstName,
        lastName: reference.lastName,
        email: reference.email,
      }) !== JSON.stringify({
        firstName: oldReference.firstName,
        lastName: oldReference.lastName,
        email: oldReference.email,
      })) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.dialog-overlay__modal__footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.section{
  margin-bottom: 2em;
}

.section__reference{
  &:not(:last-child) {
    > div:last-child {
      margin-bottom: 1.5em;
      padding-bottom: 1.5em;
      border-bottom: 1px solid $light-grey;
    }
  }
}

.section__title{
  display: flex;
  flex-direction: row;

  >h1,h2,h3,h4,h5{
    flex: 1 0 auto;

    margin-bottom: 1.5rem;
  }

  >.section__control{
    flex: 0 0 auto;
  }
}

.section__image{
  >img{
    max-width:100%;
    width: 100%;
    object-fit: contain;
    max-height: 20em;
  }
}

.quantity_input{
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
